
import { SearchSongFilter } from '@/models/SearchSongFilter';
import { SearchSongService } from '@/services/SearchSongService';
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CurrentFilters extends Vue {

  store = SearchSongService;

  removeFilter(filter: SearchSongFilter){ 
    if (filter.category === 'Administrators'){ 
      this.store.query.filters.adminIds = this.store.query.filters.adminIds.filter(adminId => adminId !== filter.id)
    }else if (filter.category === 'Contributors'){ 
      this.store.query.filters.contributorNames = this.store.query.filters.contributorNames.filter(contributorName => contributorName !== filter.label)
    }else if (filter.category === 'Catalogs'){ 
      this.store.query.filters.catalogNames = this.store.query.filters.catalogNames.filter(catalogName => catalogName !== filter.label)
    }
    this.store.setQueryParams();
  }
}
